import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { menuData } from '../data/MenuData';
import { Button } from './Button';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { FaBars } from 'react-icons/fa';

const Nav = styled.nav`
  height: 80px;
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
  z-index: 100;
  position: absolute;
  width: 95%;
  margin-bottom: 100px;
  border-bottom: 4px solid black;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const NavLink = css`
  color: #fff;
  display: flex;
  //padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
`;

const Logo = styled(Link)`
  ${NavLink}
  color: rgb(248, 208, 32);
  font-style: italic;
  font-size: 45px;
  margin-top: -5px;

  font-weight: 600;

  @media screen and (max-width: 500px) {
    //margin-left: -24px;
    margin-top: 0;
    font-size: 40px;
  }
`;

const MenuBars = styled(FaBars)`
  color: rgb(248, 208, 32);

  display: block;
  color: rgb(248, 208, 32);
  height: 30px;
  width: 40px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  left: 30px;
  transform: translate(-50%, 25%);
`;
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -48px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavMenuLinks = styled(Link)`
  ${NavLink};
  border-bottom: 2px solid #000d1a;
  background: #000;

  &:hover {
    border-bottom: 1px solid #fff;
  }
`;

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  position: absolute;
  right: 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Navbar2 = ({ toggle }) => {
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <Container>
      {loading ? null : (
        <Nav>
          <Logo data-aos="fade" to="/">
            CANVAS
          </Logo>
          <MenuBars onClick={toggle} />
          <NavBtn>
            <Button to="/subscribe">Subscribe</Button>
          </NavBtn>
        </Nav>
      )}
    </Container>
  );
};

export default Navbar2;
