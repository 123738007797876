import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { menuData } from '../data/MenuData';
import { Button } from './Button';
import { FaBars } from 'react-icons/fa';

const Nav = styled.nav`
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  z-index: 100;
  position: absolute;
  width: 100%;
  margin-bottom: 100px;
  border-bottom: 4px solid black;
`;

const NavLink = css`
  color: #fff;
  display: flex;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
`;

const Logo = styled(Link)`
  ${NavLink}
  color: rgb(248, 208, 32);
  font-style: italic;
  font-size: 30px;
  margin-top: -5px;
  font-weight: 500;

  @media screen and (max-width: 500px) {
    margin-left: -24px;
  }
`;

const MenuBars = styled(FaBars)`
  display: none;
  color: rgb(248, 208, 32);

  @media screen and (max-width: 768px) {
    display: block;
    color: rgb(248, 208, 32);
    height: 30px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 0;
    transform: translate(-50%, 25%);
  }
`;
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -48px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavMenuLinks = styled(Link)`
  ${NavLink};
  border-bottom: 2px solid #000d1a;
  background: #000;

  &:hover {
    border-bottom: 1px solid #fff;
  }
`;

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Navbar = ({ toggle }) => {
  return (
    <Nav>
      <Logo to="/">CANVAS</Logo>
      <MenuBars onClick={toggle} />
      <NavMenu>
        {menuData.map((item, index) => (
          <NavMenuLinks to={item.link} key={index}>
            {item.title}
          </NavMenuLinks>
        ))}
      </NavMenu>
      <NavBtn>
        <Button to="/subscribe">Subscribe</Button>
      </NavBtn>
    </Nav>
  );
};

export default Navbar;
