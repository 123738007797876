import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Button } from './Button';
import './Slider.css';
import { IoMdArrowRoundForward } from 'react-icons/io';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { ButtonEl } from './ButtonElements';
import Animated from './Animation/Animated';

const HeroSection = styled.section`
  height: 100vh;
  max-height: 1100px;
  position: relative;
  overflow: hidden;
  background: #000;
`;

const HeroWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const HeroSlide = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
`;
const HeroSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeroImage = styled.img`
  position: absolute;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transition: 1s ease;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    bottom: 0vh;
    left: 0;
    overflow: hidden;
    opacity: 0.4;
    transition-duration: 1s;
    transform: scale(1.08);
  }
`;
const HeroContent = styled.div`
  position: relative;
  top: 30%;
  left: 50px;
  //left: 500px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: calc(100% - 200px);
  color: #000d1a;
  background: rgba(0, 0, 00, 0.8);
  background-color: rgba(220, 220, 220);
  padding: 4rem;
  //color: #fff;
  h1 {
    font-size: clamp(3rem, 8vw, 5rem);
    font-weight: 400;
    //color: yellow;
    font-family: 'Poppins';
    text-transform: uppercase;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    margin-bottom: 0.8rem;
    padding-bottom: 15px;
    font-style: italic;
    //padding-top: 25px;
  }

  p {
    margin-bottom: 1.2rem;
    font-size: 1.1rem;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    //color: #000d1a;
    //color: #fff;
    font-weight: 400;
  }
  @media screen and (max-width: 768px) {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    left: 0;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    //background-color: rgba(0, 0, 0, 0.8);
    //color: #fff;
  }

  @media screen and (max-width: 460px) {
    //width: 90%;
  }

  @media screen and (min-width: 1000px) {
    width: 700px;
    padding: 3rem;
    //margin-left: -500px;
  }
`;
const Arrow = styled(IoMdArrowRoundForward)`
  margin-left: 0.5rem;
  top: 20px;
`;

const SliderButtons = styled.div`
  position: absolute;
  bottom: 50px;
  right: 50px;
  display: flex;
  z-index: 10;
`;

const arrowButtons = css`
  width: 50px;
  height: 50px;
  color: #fff;
  cursor: pointer;
  background: #000d1a;
  border-radius: 50px;
  padding: 10px;
  margin-right: 1rem;
  user-select: none;
  transition: 0.3s;

  &:hover {
    background: #cd853f;
    transform: scale(1.05);
  }
`;

const PrevArrow = styled(IoArrowBack)`
  ${arrowButtons}
  &:hover {
    box-shadow: 5px -5px 15px 0 rgba(248, 208, 32, 0.5),
      -5px 5px 15px 0 rgba(248, 208, 32, 0.5);
  }
`;

const NextArrow = styled(IoArrowForward)`
  ${arrowButtons}
  &:hover {
    box-shadow: 10px -10px 25px 0 rgba(248, 208, 32, 0.5),
      -10px 10px 25px 0 rgba(248, 208, 32, 0.5);
    rotate: (150deg);
  }
`;
const Div = styled.div`
  height: 100%;
  width: 100%;
  //position: absolute;
  background: linear-gradient(
    90deg,
    transparent 0% 33%,
    rgba(0, 0, 0) 80%,
    rgba(0, 0, 0) 80%,
    rgba(0, 0, 0) 100%
  );
  z-index: 20;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
`;
const BtnWrap = styled.div`
  max-width: 500px;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #fff;
`;
const Header = styled.h1`
  font-size: 3.5rem;
  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Hero = ({ slides }) => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  const [current, setCurrent] = useState(0);
  const length = slides.length;
  const timeout = useRef(null);

  useEffect(() => {
    const nextSlide = () => {
      setCurrent((current) => (current === length - 1 ? 0 : current + 1));
    };

    timeout.current = setTimeout(nextSlide, 5000);

    return function () {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [current, length]);

  const nextSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    setCurrent(current === length - 1 ? 0 : current + 1);

    console.log(current);
  };

  const prevSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <HeroSection>
      {loading ? null : (
        <HeroWrapper data-aos="fade">
          {slides.map((slide, index) => {
            return (
              <div className={index === current ? 'slide-active' : 'slide'}>
                <HeroSlide key={index}>
                  {index === current && (
                    <HeroSlider>
                      <HeroImage src={slide.image} alt={slide.alt} />

                      <Div>
                        <HeroContent>
                          <div
                            className={
                              index === current ? 'header-active' : 'header'
                            }
                          >
                            <Header>
                              <Animated text="Made to Inspire" />
                            </Header>
                          </div>
                          <p>
                            Canvas is a literary and arts publication made for
                            those with a passion for art and poetry.{' '}
                          </p>
                          <BtnWrap>
                            <ButtonEl
                              onMouseEnter={onHover}
                              onMouseLeave={onHover}
                              to={slide.path}
                              header="Discover"
                            >
                              {slide.label}
                            </ButtonEl>
                          </BtnWrap>
                        </HeroContent>
                      </Div>
                    </HeroSlider>
                  )}
                </HeroSlide>
              </div>
            );
          })}
          <SliderButtons>
            <PrevArrow onClick={prevSlide} />
            <NextArrow onClick={nextSlide} />
          </SliderButtons>
        </HeroWrapper>
      )}
    </HeroSection>
  );
};

export default Hero;
