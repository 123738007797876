import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonEl } from '../ButtonElements';

import './Cards2.css';
function CardItem2(props) {
  return (
    <>
      <li className="cards__item1">
        <Link className="cards__item__link" to={props.path}>
          <figure
            className="cards__item__pic-wrap1"
            data-category={props.label}
          >
            <img
              src={props.src}
              alt="Brickwork"
              className="cards__item__img1"
            />
          </figure>
          <div className="cards__item__info">
            <h1 className="cards__item__price1">{props.price}</h1>

            <h5 className="cards__item__text1">{props.text}</h5>
            <h5 className="cards__item__text22">{props.text2}</h5>

            <h4 className="cards__item__miles">
              <i class="fa-solid fa-car"></i>
              {props.miles}
            </h4>
            <ButtonEl
              to={props.path}
              primary="true"
              dark="true"
              className="price__button"
            >
              Details
            </ButtonEl>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem2;
