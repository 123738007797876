import React from 'react';
import styled from 'styled-components';
import Img1 from '../../images/canvas2.jpg';
import { Button } from '../Button';
import { ButtonEl2 } from '../ButtonEl2';
import { ButtonEl } from '../ButtonElements';

const Container = styled.div`
  height: 100vh;
  background-color: #000;
  //background: url('/images/canvas2.jpg.jpg') fixed center;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  //z-index: 0;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  color: #fff;
  width: 500px;
`;
const Content = styled.div`
  font-size: 2.5rem;
  position: absolute;
  z-index: 3;
  left: 60%;
  top: 15%;
  //background: #000;
  //top: 0;
  @media screen and (max-width: 768px) {
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
    top: 10%;
    font-size: 1.8rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 1.8rem;
    top: 20%;
  }
`;
const Div = styled.div`
  height: 745px;
  width: 100%;
  //position: absolute;
  background: linear-gradient(
    90deg,
    transparent 0% 33%,
    rgba(0, 0, 0) 60%,
    rgba(0, 0, 0) 60%,
    rgba(0, 0, 0) 100%
  );
  z-index: 2;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    // display: none;
    background: rgba(0, 0, 0, 0.8);
    height: 100vh;
  }
`;
const Span = styled.span`
  color: rgb(248, 208, 32);
`;
const HeroImage = styled.img`
  position: absolute;

  //top: 0;
  //left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transition: 1s ease;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    bottom: 0vh;
    left: 0;
    overflow: hidden;
    opacity: 0.4;
    transition-duration: 1s;
    transform: scale(1.08);
  }
`;
const BtnWrap = styled.div`
  position: absolute;
  z-index: 3;
  left: 60%;
  top: 85%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 200px;
  @media screen and (max-width: 768px) {
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    margin-left: 100px;
  }
  @media screen and (max-width: 768px) {
    top: 82.5%;
  }
`;
const Content2 = styled.div`
  font-size: 1.5rem;
  position: absolute;
  z-index: 3;
  left: 60%;
  top: 75%;
  text-align: center;
  @media screen and (max-width: 768px) {
    left: 0;
    margin-right: 6px;
    margin-left: 6px;
  }
  @media screen and (max-width: 768px) {
    top: 70%;
  }
`;

const Subscribe = () => {
  return (
    <Container>
      <Wrapper>
        <HeroImage src={Img1} />
        <Div>
          <ContentWrapper>
            <Content>
              A mix of your <Span>creativity</Span> and ours, a love note to
              unthemed marginalia and memories written inside birthday cards. An
              <Span> antidote</Span> to cover letters plastic-wrapped flashcards
              and "hope you have had a good holiday and are well-rested" emails.
            </Content>
            <Content2>
              Join the Canvas community to be notified for every new realease
            </Content2>
            <BtnWrap>
              <Button to="/subscribe">Subscribe</Button>
            </BtnWrap>
          </ContentWrapper>
        </Div>
      </Wrapper>
    </Container>
  );
};

export default Subscribe;
