import React, { useState } from 'react';
import styled from 'styled-components';
import { TopBit } from '../Modal';
import { data } from './Data';
import './Gallery.css';
import GallerySlider from '../GallerySlider';
import GallerySlider2 from '../GallerySlider2';

export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #000;
  color: #fff;
`;
export const GalleryH1 = styled.h1`
  margin-top: 64px;
  text-align: center;
`;

const Gallery2 = () => {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(item.image);
    console.log(clickedImg);
  };

  const handleRotationRight = () => {
    const totalLength = data.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = data[0].image;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = data.filter((item) => {
      return data.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].image;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };
  return (
    <>
      <GalleryContainer>
        <TopBit />
        <GalleryH1>Here are some of our favourite pages:</GalleryH1>
        <div>
          {data.map((item, index) => (
            <div key={index} className="wrapper">
              <img
                src={item.image}
                alt={item.text}
                onClick={() => handleClick(item, index)}
              />
              <div className="credits-wrapper">
                <h2 className="gallery-text">{item.text}</h2>
                <p className="gallery-p">{item.text2}</p>
                <h2 className="gallery-poet">{item.poet}</h2>
                <p className="gallery-poem">{item.poem}</p>
              </div>
            </div>
          ))}
          {clickedImg && (
            <GallerySlider2
              clickedImg={clickedImg}
              handleRotationRight={handleRotationRight}
            />
          )}
        </div>
      </GalleryContainer>
    </>
  );
};

export default Gallery2;
