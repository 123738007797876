import React from 'react';
import Icon1 from '../../images/issue2.JPG';
import Icon2 from '../../images/issue11.JPG';
import Icon3 from '../../images/issue3.JPG';
import {
  ValueCard,
  ValueContainer,
  ValueH1,
  ValueH2,
  ValueIcon,
  ValueP,
  ValueWrapper,
} from './ValueElements';

const CanvasIssues = () => {
  return (
    <ValueContainer id="services">
      <ValueH1>Check out our latest Issues:</ValueH1>
      <ValueWrapper>
        <a
          href="https://issuu.com/canvaszinecambridge/docs/upload_copy_"
          alt="canvas issue 1"
        >
          <ValueCard>
            <ValueIcon src={Icon2} />
            <ValueH2>Issue I</ValueH2>
            <ValueP></ValueP>
          </ValueCard>
        </a>
        <a
          href="https://issuu.com/canvaszinecambridge3/docs/final_draft"
          alt="magazine"
        >
          <ValueCard>
            <ValueIcon src={Icon3} />
            <ValueH2>Issue III</ValueH2>
            <ValueP>This is our latest issue. We hope you enjoy.</ValueP>
          </ValueCard>
        </a>
        <a
          href="https://issuu.com/canvaszinecambridge/docs/c.d.25_4"
          alt="canvas issue 2"
        >
          <ValueCard>
            <ValueIcon src={Icon1} />
            <ValueH2>Issue II</ValueH2>
            <ValueP></ValueP>
          </ValueCard>
        </a>
      </ValueWrapper>
    </ValueContainer>
  );
};

export default CanvasIssues;
