import React from 'react';
import Img1 from '../../images/canvas2.jpg';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
const HeroImage = styled.img`
  //position: absolute;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transition: 1s ease;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    bottom: 0vh;
    left: 0;
    overflow: hidden;
    opacity: 0.4;
    transition-duration: 1s;
    transform: scale(1.08);
  }
`;
const Wrapper = styled.div``;

const Info = () => {
  return (
    <Container>
      <HeroImage src={Img1} />
      <Wrapper></Wrapper>
    </Container>
  );
};

export default Info;
