import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ButtonEl = styled(Link)`
  text-decoration: none;
  padding: 20px 50px;
  font-size: 1.25rem;
  position: relative;
  color: #000d1a;
  //border: 3px solid #000;

  &::after,
  &::before {
    border: 3px solid #000d1a;
    content: '';
    position: absolute;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    left: 0;
    bottom: 0;
    //z-index: -1;
    transition: transform 0.3s ease;
    @media screen and (max-width: 768px) {
      border: 3px solid #fff;
    }
  }
  &:hover {
    transition: 0.3s ease;
    color: rgba(0, 0, 0, 0.8);
    @media screen and (max-width: 768px) {
      color: #fff;
    }
  }
  &:hover:after {
    transform: translate(-5px, -5px);
  }
  &:hover:before {
    transform: translate(5px, 5px);
  }
  @media screen and (max-width: 768px) {
    color: #fff;
  }
`;
