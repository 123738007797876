import Navbar from './components/Navbar';

import GlobalStyle from './globalStyle';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import { Modal } from './components/Modal';
import GalleryPage from './pages/GalleryPage';
import IssuePage from './pages/IssuePage';
import Dropdown from './components/Dropdown';
import { useState } from 'react';
import AboutPage from './pages/AboutPage';
import GalleryPage2 from './pages/GalleryPage2';
import Navbar2 from './components/Navbar2';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <GlobalStyle />
      <Router>
        <Navbar2 toggle={toggle} />
        <Dropdown isOpen={isOpen} toggle={toggle} />
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/subscribe" element={<Modal />}></Route>
          <Route path="/gallery" element={<GalleryPage />}></Route>
          <Route path="/issues" element={<IssuePage />}></Route>
          <Route path="/about" element={<AboutPage />}></Route>
          <Route path="/gallery2" element={<GalleryPage2 />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
