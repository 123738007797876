import Img1 from '../../images/ornamentPage.JPG';
import Img2 from '../../images/boob.JPG';
import Img3 from '../../images/wingMan.JPG';
import Img4 from '../../images/ceilingPage.JPG';
import Img5 from '../../images/cupPage.JPG';

export const data = [
  {
    image: Img1,
    text: 'Photograph by Anna Piper Thompson',
    text2:
      'Photograph: Behind the scenes at Museo Archeologico Nazional di Napoli',
    poet: 'Poem by Isabella Bottle',
    poem: 'Poem: Something from Somewhere Else',
  },
  {
    image: Img2,
    text: 'Artwork by Caitlin van Bommel',
    //text2: 'Behind the scenes at Museo Archeologico Nazional di Napoli',
    poet: 'Poem by Liberty Halifax Beswick',
    poem: 'Poem: Structure',
  },
  {
    image: Img3,
    text: 'Artwork by Dominique Pila',
    //text2: 'Behind the scenes at Museo Archeologico Nazional di Napoli',
    poet: 'Prose by Libby Harris',
    poem: 'Prose: FIGS RIPENING',
  },
  {
    image: Img4,
    text: 'Photograph by Szilvi Daczo',
    //text2: 'Behind the scenes at Museo Archeologico Nazional di Napoli',
    poet: 'Poem by Jack Heath',
    poem: 'Poem: Allegory',
  },
  {
    image: Img5,
    text: 'Potery by Aisha Sobey',
    //text2: 'Behind the scenes at Museo Archeologico Nazional di Napoli',
    poet: 'Poem by Sylvie Lewis',
    poem: 'Poem: A Statue in the City',
  },
];
