import React, { useState } from 'react';
import Dropdown from '../components/Dropdown';
import Hero from '../components/Hero';
import InfoSection from '../components/InfoSection';
import { homeObjectOne } from '../components/InfoSection/Data';
import Issues from '../components/InfoSection2';
import { InfoData } from '../components/InfoSection2/InfoData';
import '../canvasProject.css';
import Navbar from '../components/Navbar';
import { SliderData } from '../data/SliderData';
import GlobalStyle from '../globalStyle';
import Footer from '../components/Footer/Footer';
import Navbar2 from '../components/Navbar2';
import But from '../components/But';
import Info from '../components/Info/Info';
import MobileLatest from '../components/Show/MobileLatest';

import Carousel from '../components/Carousel/Carousel';
import styled from 'styled-components';
import Subscribe from '../components/Subscribe/Subscribe';

const Container = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: 300px;
  @media screen and (max-width: 768px) {
    margin-bottom: 500px;
  }
`;

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Container>
        <GlobalStyle />

        <Hero slides={SliderData} />
        <Carousel />
        <Subscribe />
      </Container>
      <Footer />
    </>
  );
};

export default HomePage;
