import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import imageOne from '../../images/issue3.JPG';
import { Link } from 'react-router-dom';

const Section = styled.section`
  width: 100%;

  height: 100%;
  z-index: 999;
  padding: 4rem 0rem;
  padding-top: 5rem;
  margin-top: 8rem;
  margin-bottom: 15rem;
  background-color: #000d1a;
  color: #fff;

  /* @media screen and (max-width: 768px) {
    background: #f9f9f9;
    color: #000d1a;
  }*/
  @media screen and (max-width: 768px) {
    padding-top: 10rem;
  }
`;

const Container = styled.div`
  padding: 3rem calc() ((10vw - 1300px) /2);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const ColumnLeft = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.4;
  padding: 1rem 2rem;
  order: ${({ reverse }) => (reverse ? '1' : '2')};

  h1 {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }

  p {
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 780px) {
    h1 {
      font-size: 3rem;
    }
  }
`;
const ColumnRight = styled.div`
  padding: 1rem 2rem;
  order: ${({ reverse }) => (reverse ? '2' : '1')};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    order: ${({ reverse }) => (reverse ? '1' : '2')};
  }

  img {
    width: 100%;
    height: 60vh;
    object-fit: cover;

    @media screen and (max-width: 768px) {
      width: 90%;
      height: 50vh;
      margin-top: 120px;
    }
  }
`;

const Issues = (
  heading,
  paragraphOne,
  paragraphTwo,
  buttonLabel,
  reverse,
  image
) => {
  return (
    <Section>
      <Container>
        <ColumnLeft>
          <h1>Explore our beautiful magazine</h1>
          <p>
            Our amazing zine is right at your finger tips. Each Issue is as good
            as the last:
          </p>

          <Button to="/issues">View our Issues</Button>
        </ColumnLeft>
        <ColumnRight reverse={reverse}>
          <img src={imageOne} alt="art" />
        </ColumnRight>
      </Container>
    </Section>
  );
};

export default Issues;
