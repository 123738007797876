import React from 'react';
import styled from 'styled-components';
import { ButtonEl } from './ButtonElements';

const Cont = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const But = (props) => {
  return (
    <Cont>
      <ButtonEl>{props.header}</ButtonEl>
    </Cont>
  );
};

export default But;
