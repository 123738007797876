import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button.js';
import './Footer.css';

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>About us</h2>
            <Link to="/about">About us</Link>
            <Link to="/gallery">Recent releases</Link>
            <Link to="/gallery">Gallery</Link>
          </div>
          <div className="footer-link-items">
            <h2>Contact us</h2>
            <Link to="/subscribe">How to get in touch</Link>
            <Link to="/subscribe">Drop your email</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Social media</h2>
            <a
              href="https://instagram.com/canvaszinecambridge?igshid=YmMyMTA2M2Y="
              alt="ig"
            >
              Instagram
            </a>
          </div>
          <div className="footer-link-items">
            <h2>Issues</h2>
            <Link to="/issues">View Issues</Link>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo canvas">
              Canvas
            </Link>
          </div>
          <small className="website-rights">Canvas © 2020</small>
          <div className="social-icons">
            <div
              className="social-icon-link instagram"
              to="/"
              target="_blank"
              aria-label="Instagram"
            >
              <a
                href="https://instagram.com/canvaszinecambridge?igshid=YmMyMTA2M2Y="
                alt="instagram"
              >
                <i className="fa fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
