import React from 'react';
import styled from 'styled-components';
import Img2 from '../../images/AbtPge2.JPG';
import Img1 from '../../images/AbtPge1.JPG';
import Img3 from '../../images/AboutBack.jpg';

export const AboutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  padding-top: 6rem;
  background-image: url(${Img3});
  z-index: -1;
  height: 1500px;
  width: 100%;
  @media screen and (max-width: 600px) {
    padding: 5px;
    padding-top: 0;
  }
`;
export const AboutBackground = styled.div``;
export const AboutImgWrap = styled.div`
  //margin-top: -400px;
  display: flex;
  align-items: left;
  justify-content: left;
  // margin-left: -200px;
  margin-top: -100px;
`;
export const Img = styled.img`
  width: 90%;
  height: 90%;
  /*margin-right: 500px;
  margin-top: 50px;*/
  margin-left: -100px;

  @media screen and (max-width: 1400px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 1200px) {
    margin-top: -200px;
  }
  @media screen and (max-width: 800px) {
    margin-top: -400px;
    height: 100%;
    width: 90vw;
  }
  @media screen and (max-width: 500px) {
    margin-top: -500px;
    width: 100vw;
  }
`;
export const Image = styled.img`
  width: 90%;
  height: 90%;
  //margin-left: 10%;
  margin-top: 100px;
  margin-left: 200px;

  @media screen and (max-width: 1200px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 800px) {
    height: 100%;
    width: 90vw;
    margin-top: 50px;
  }
  @media screen and (max-width: 500px) {
    margin-top: -200px;
    width: 100vw;
  }
`;
export const AboutImg2Wrap = styled.div``;

const About = () => {
  return (
    <>
      <AboutContainer>
        <AboutBackground>
          <AboutImgWrap>
            <Img src={Img1} alt="about canvas" />
          </AboutImgWrap>
          <AboutImg2Wrap>
            <Image src={Img2} alt="about canvas" />
          </AboutImg2Wrap>
        </AboutBackground>
      </AboutContainer>
    </>
  );
};

export default About;
