import { Link } from 'react-router-dom';
import './Gallery/Gallery.css';

const GallerySlider = ({ clickedImg, setClickedImg, handleRotationRight }) => {
  const handleClick = (e) => {
    window.location.reload(false);
    /*if (e.target.classList.contains('dismiss')) {
      setClickedImg(null);
    }*/
    // setClickedImg(null);
  };
  return (
    <div className="overlay dismiss">
      <img src={clickedImg} alt="bigger picture" />

      <span className="dismiss xmark">
        <Link to="/gallery2">X</Link>
      </span>

      <div onClick={handleRotationRight} className="overlay-arrows__left">
        <i class="fa-solid fa-circle-arrow-left"></i>
      </div>
      <div onClick={handleRotationRight} className="overlay-arrows__right">
        <i class="fa-solid fa-circle-arrow-right"></i>
      </div>
    </div>
  );
};

export default GallerySlider;
