import React from 'react';
import styled from 'styled-components';

export const Header = styled.div`
  background: #000d1a;
  padding: 2rem;
`;
export const Content = styled.h1`
  padding: 4rem;
  color: red;
`;

export const ModalHeader = (showModal, setShowModal) => {
  return <>{showModal ? <div>Modal</div> : null}</>;
};
