import canvasOne from '../images/darkPic1.jpg';
import canvasFour from '../images/darkPic2.jpg';
import canvasThree from '../images/darkPic3.jpg';
import canvasTwo from '../images/twoFace.jpg';
/*import canvasFour from '../images/canvas4.jpg';
import canvasFive from '../images/canvas5.jpg';
import canvasSix from '../images/canvas6.jpg';*/

export const SliderData = [
  {
    title: <div className="canvas">Canvas</div>,
    author: 'A themeless literary and arts publication',
    path: '/issues',
    label: 'Discover',
    image: canvasTwo,
    alt: 'art',
  },
  {
    title: <div className="canvas">Canvas</div>,
    author: 'A themeless literary and arts publication',
    path: '/issues',
    label: 'Discover',
    image: canvasOne,
    alt: 'art',
  },
  {
    title: <div className="canvas">Canvas</div>,
    author: 'A themeless literary and arts publication',
    path: '/issues',
    label: 'Discover',
    image: canvasThree,
    alt: 'art',
  },
  {
    title: <div className="canvas">Canvas</div>,
    author: 'A themeless literary and arts publication',
    path: '/issues',
    label: 'Discover',
    image: canvasFour,
    alt: 'art',
  },
  /*
  {
    title: 'Canvas issue II',
    author: 'Poet: Jack Health, Editor: Scarlett Ryan',
    path: '/gallery',
    label: 'View Gallery',
    image: canvasFour,
    alt: 'art',
  },*/
];
