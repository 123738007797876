import styled from 'styled-components';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';
import { Button } from './Button.js';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 50%;
  //box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #f9f9f9;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  padding-bottom: 8rem;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const TopBit = styled.div`
  width: 100%;
  padding: 2rem;
  background: #000;
  height: 80px;
`;

export const ModalImg = styled.img`
  padding-top: 5rem;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background: #000;
  object-fit: cover;
`;

export const ModalContent = styled.div``;

export const Modal = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_zfe3grh',
        'template_b3why0g',
        form.current,
        'WagdLhtLdSqEJBjF0'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <ModalWrapper>
        <ModalContent>
          <TopBit />
          <section>
            <div className="contact">
              <h1 className="contact-header">Subscribe to the zine</h1>
              <h2 className="contact-sub-header">
                Recieve updates on our latest releases
              </h2>
              <div className="contact-container">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="contact-form --card"
                >
                  <div className="contact-input-items">
                    <p className="contact-input-header">Name:</p>
                    <input
                      type="text"
                      className="input-box"
                      placeholder="Full Name"
                      name="user_name"
                      required
                    />
                  </div>
                  <div className="contact-input-items">
                    <p className="contact-input-header">Email:</p>
                    <input
                      className="input-box"
                      type="email"
                      placeholder="Email"
                      name="user_email"
                      required
                    />
                  </div>
                  <div className="contact-input-items">
                    <p className="contact-input-header">Subject:</p>
                    <input
                      className="input-box"
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      required
                    />
                  </div>
                  <div className="contact-input-items">
                    <p className="contact-input-header">Send us a Message:</p>
                    <textarea
                      className="input-box"
                      placeholder="(optional)"
                      name="message"
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                  <div className="contact-input-items">
                    <div className="contact-btn">
                      {' '}
                      <Button
                        className="contact-btn"
                        buttonStyle="btn--primary"
                        buttonSize="btn--large"
                        type="submit"
                      >
                        {' '}
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </ModalContent>
        <ModalImg src={require('../images/darkPic1.jpg')} />
      </ModalWrapper>
    </>
  );
};
