import React from 'react';
import CanvasIssues from '../components/Issues';

const IssuePage = () => {
  return (
    <>
      <CanvasIssues />
    </>
  );
};

export default IssuePage;
