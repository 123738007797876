import React, { useState } from 'react';
import Gallery from '../components/Gallery';
import { data } from '../components/Gallery/Data';
import Gallery2 from '../components/Gallery/index2';

const GalleryPage2 = () => {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(item.image);
  };

  const handleRotationRight = () => {
    const totalLength = data.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = data[0].image;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = data.filter((item) => {
      return data.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].image;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  return (
    <Gallery2
      {...data}
      clickedImg={clickedImg}
      handleClick={handleClick}
      currentindex={currentIndex}
      handleRotationRight={handleRotationRight}
    />
  );
};

export default GalleryPage2;
