import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Img1 from '../../images/issue1.JPG';
import Img2 from '../../images/issue3.JPG';
import Img3 from '../../images/issue2.JPG';
import './Carousel.css';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Button } from '../Button';
import { ButtonEl } from '../ButtonElements';

const Container = styled.div`
  height: 120vh;
  background: rgb(5, 5, 5);
  margin-top: -250px;
  @media screen and (max-width: 600px) {
    height: 120vh;
    margin: 0;
  }
  @media screen and (max-width: 380px) {
    height: 150vh;
  }
`;
const Header = styled.h1`
  font-style: italic;
  color: #fff;
  font-size: 3rem;
  padding: 25px;
  padding-top: 75px;
`;
const SubHeader = styled.div`
  font-size: 1.1rem;
  text-align: center;
  color: #fff;
`;
const Span = styled.span`
  font-style: italic;
`;
const BottomHeaderWrap = styled.div``;
const BottomHeader = styled.div``;
const BtnWrap = styled.div``;
const ContentWrap = styled.div`
  color: #fff;
`;

const Carousel = () => {
  const [index, setIndex] = useState(0);

  const cards = [
    { id: 1, image: Img1 },
    { id: 2, image: Img2 },
    { id: 3, image: Img3 },
  ];
  const mod = (n, m) => {
    let result = n % m;

    return result >= 0 ? result : result + m;
  };

  useEffect(() => {
    setTimeout(() => {
      setIndex((index + 1) % cards.length);
    }, 3000);
  }, [index]);
  const [mousePosition, setmousePosition] = useState({
    x: 0,
    y: 0,
  });
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  useEffect(() => {
    const mouseMove = (e) => {
      setmousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener('mousemove', mouseMove);

    return () => {
      window.removeEventListener('mousemove', mouseMove);
    };
  }, []);

  const variants = {
    default: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
    },
  };

  return (
    <>
      <motion.div className="cursor" variants={variants} animate="default" />
      <Container>
        <Header data-aos="fade">DISCOVER CANVAS</Header>
        <SubHeader data-aos="fade">
          Indulge in art work and poetry.{' '}
          <Span>Canvas is what you make it out to be.</Span>
        </SubHeader>

        <div className="carousel">
          {cards.map((item, i) => {
            const indexLeft = mod(index - 1, cards.length);
            const indexRight = mod(index + 1, cards.length);

            let className = '';
            if (i === index) {
              className = 'card card--active';
            } else if (i === indexRight) {
              className = 'card card--right';
            } else if (i === indexLeft) {
              className = 'card card--left';
            } else {
              className = 'card';
            }
            return (
              <Link to="/issues">
                <img
                  key={item.id}
                  src={item.image}
                  alt=""
                  className={className}
                />
              </Link>
            );
          })}
        </div>
      </Container>
    </>
  );
};

export default Carousel;
